<template>
  <div class="home">
    <div>
      <header>
        <h1>Retirement calculator</h1>
        <label class="toggle-button" for="settings">

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu-button">
          <g v-if="!settings">
            <line x1="6.5" y1="9.5" x2="33.5" y2="9.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="18.5" x2="33.5" y2="18.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="27.5" x2="33.5" y2="27.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
          </g>
          <g v-else>
            <line x1="6.5" y1="27.5" x2="33.5" y2="9.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="9.5" x2="33.5" y2="27.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
          </g>
        </svg>

        </label>
      </header>
      <svg width="1200" height="800" viewBox="0 0 1200 800" preserveAspectRatio="none" ref="svg">
        <defs>
          <mask id="graphMask">
            <rect x="0" y="0" :width="svg.width" :height="svg.height" fill="#000000" stroke="" stroke-width=""/>
            <rect :x="svg.paddingH" :y="svg.padding" :width="graphWidth - 12" :height="graphHeight" fill="#ffffff" stroke="" stroke-width=""/>
          </mask>
          <linearGradient id="gradient" x1="13.759" :y1="svg.padding" x2="13.759" :y2="svg.padding + graphHeight" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3DAFD"/>
            <stop offset="1" stop-color="#F8F6FE"/>
          </linearGradient>
        </defs>

        <!-- BG lines -->
        <g v-for="val in yAxis">
          <line :x1="pointX(0)" :y1="pointY(val)" :x2="pointX(yearsOnGraph)" :y2="pointY(val)" stroke="#cccccc" stroke-width=""/>
        </g>


        <!-- The main line -->
        <path :d="path + 'z'"  stroke-width="" stroke="#643DF6" mask="url(#graphMask)" fill="url(#gradient)"/>
        <!-- <g v-for="(amt, year) in graphFigures" mask="url(#graphMask)">
          <circle r="3" :cx="pointX(year)" :cy="pointY(amt)" :key="'c'+year"/>
        </g> -->


        <!-- x axis... -->
        <line :x1="pointX(0)" :y1="pointY(0)" :x2="pointX(maxAge - age)" :y2="pointY(0)" stroke="#000000" stroke-width=""/>


        <!-- Age now -->
        <line :x1="pointX(0)" :y1="pointY(0)" :x2="pointX(0)" :y2="pointY(max)" stroke="#000000" stroke-width=""/>
        <text :x="pointX(0) + 10" :y="pointY(0) - 20" fill="">Age ({{ age }})</text>
        

        <!-- Retirement age -->
        <line :x1="pointX(retirementAge - age)" :y1="pointY(0)" :x2="pointX(retirementAge - age)" :y2="pointY(max)" stroke="#643DF6" stroke-width="1" style="opacity: 0.5"/>
        <text :x="pointX(retirementAge - age) + 10" :y="pointY(0) - 20" fill="">Retirement ({{ retirementAge }})</text>
        
        <!-- Key area highlight -->
        <rect :x="pointX(retirementAge - age)" :y="pointY(max)" :width="(yearsOfRetirementToLifeExpectancy / yearsOnGraph) * graphWidth" :height="graphHeight" fill="#643DF6" stroke="" stroke-width="" style="pointer-events: none; opacity: 0.04;"/>

        <!-- Life expectancy -->
        <line :x1="pointX(lifeExpectancy - age)" :y1="pointY(0)" :x2="pointX(lifeExpectancy - age)" :y2="pointY(max)" stroke="#643DF6" stroke-width="1" style="opacity: 0.5"/>
        <text :x="pointX(lifeExpectancy - age) + 10" :y="pointY(0) - 20" fill="">Life expectancy ({{ lifeExpectancy }})</text>

        <text alignment-baseline="middle" text-anchor="end" :x="pointX(0) - 5" :y="pointY(initial)" fill="">{{ initial | shorthand }}</text>
        <text alignment-baseline="middle" text-anchor="end" :x="pointX(0) - 5" :y="pointY(max)" fill="">{{ max | shorthand }}</text>

        <!-- Y axis -->
        <g v-for="val in yAxis">
          <text alignment-baseline="middle" text-anchor="end" :x="pointX(0) - 5" :y="pointY(val)" fill="">{{ val | shorthand }}</text>
        </g>

        <!-- Overlay -->
        <rect id="info-layer" :x="svg.paddingH" :y="svg.padding" :width="graphWidth" :height="graphHeight" @mousemove="updateMouse($event)" fill="transparent"></rect>
        <g :transform="keyTransform" id="key">
          <circle r="3" cx="0" cy="0" fill="#000000" stroke="" stroke-width=""/>
          <text x="5" y="10" fill="">Age: {{ mouse.age }}</text>
          <text x="5" y="30" fill="">Amount: {{ mouse.amount | shorthand }}</text>
        </g>
      </svg>
    </div>
    <input type="checkbox" id="settings" v-model="settings" />
    <div class="controls">
      <header>
        <span></span>
        <label class="toggle-button" for="settings">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu-button">
          <g v-if="!settings">
            <line x1="6.5" y1="9.5" x2="33.5" y2="9.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="18.5" x2="33.5" y2="18.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="6.5" y1="27.5" x2="33.5" y2="27.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
          </g>
          <g v-else>
            <line x1="10.5" y1="27.5" x2="29.5" y2="9.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
            <line x1="10.5" y1="9.5" x2="29.5" y2="27.5" stroke="black" stroke-width="3" stroke-linecap="round"/>
          </g>
        </svg>
      </label>
      </header>
      <div class="controls--inputs">
        <label for="age">Age</label>
        <input type="number" v-model="age" id="age"/>
        <label for="retirementAge">RetirementAge</label>
        <input type="number" v-model="retirementAge" id="retirementAge"/>
        <label for="lifeExpectancy">Life expectancy</label>
        <input type="number" v-model="lifeExpectancy" id="lifeExpectancy"/>
        <label for="initial">Current pension savings (£):</label>
        <input type="number" v-model="initial" id="initial"/>
        <label for="investmentPerMonth">Pension contributions per month (£):</label>
        <input type="number" v-model="investmentPerMonth" id="investmentPerMonth"/>
        <label for="withdrawalPerMonth">Withdrawal per month after retirement (£):</label>
        <input type="number" v-model="withdrawalPerMonth" id="withdrawalPerMonth"/>
        <label for="returnPercent">Pension plan return (%):</label>
        <input type="number" v-model="returnPercent" id="returnPercent"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
    data() {
    return {
      settings: false,
      pt: null,
      initial: 5000,
      investmentPerMonth: 500,
      withdrawalPerMonth: 500,
      returnPercent: 0,
      age: 36,
      retirementAge: 60,
      lifeExpectancy: 80,
      mouse: {
        x: 0,
        y: 0,
        age: 0,
        amount: 0
      },
      svg: {
        width: 1200,
        height: 800,
        padding: 60,
        paddingH: 90
      },
    };
  },
  computed: {
    maxAge(){
      return this.lifeExpectancy + 10
    },
    keyTransform(){
      return 'translate(' + this.mouse.x + ' ' +  this.mouse.y+')'
    },
    returnOnInvestment() {
      return this.returnPercent * 0.01;
    },
    yearsBeforeRetirement() {
      return this.retirementAge - this.age;
    },
    yearsOnGraph() {
      return this.maxAge - this.age;
    },
    yearsOfRetirement() {
      return this.maxAge - this.retirementAge;
    },
    yearsOfRetirementToLifeExpectancy() {
      return this.lifeExpectancy - this.retirementAge;
    },
    incomeBeforeRetirement() {
      let totals = [];
      let savings = this.initial;
      for (var i = 0; i <= this.yearsBeforeRetirement; i++) {
        totals.push(savings);
        savings =
          savings * (1 + this.returnOnInvestment) +
          this.investmentPerMonth * 12;
      }
      return totals;
    },
    totalBeforeRetirement() {
      let savings = this.initial;
      for (var i = 0; i < this.yearsBeforeRetirement; i++) {
        savings =
          savings * (1 + this.returnOnInvestment) +
          this.investmentPerMonth * 12;
      }
      return savings;
    },
    incomeAfterRetirement() {
      let savings = this.totalBeforeRetirement;
      let totals = [];
      for (var i = 0; i < this.yearsOfRetirement; i++) {
        savings =
          (savings - this.withdrawalPerMonth * 12) *
          (1 + this.returnOnInvestment);
        totals.push(savings);
      }
      return totals;
    },
    graphFigures() {
      return [
        ...this.incomeBeforeRetirement,
        ...this.incomeAfterRetirement,
      ]
      //.filter((val) => val > 0);
    },
    max() {
      return Math.max(...this.graphFigures);
    },
    graphWidth() {
      return this.svg.width - this.svg.paddingH * 2;
    },
    graphHeight() {
      return this.svg.height - this.svg.padding * 2;
    },
    axisSize(){

      let largest = this.max;
      let num_ticks = 5;
      let target = largest / num_ticks;
      let scale = 10 ** parseInt(Math.log10(target));
      let prev = null;
      let valid = [1, 2, 2.5, 3, 4, 5, 6, 7, 7.5, 8, 9];
      for (var vi = 0; vi < valid.length; vi++) {
        let v = valid[vi];
        if (v * scale > target) {
          if (!prev) {
            return v * scale;
          }

          let dist = v * scale - target;
          let dist_prev = target - prev * scale;

          if (dist < dist_prev) {
            return v * scale;
          } else {
            return prev * scale;
          }
        }
        prev = v;
      }
    },
    yAxis() {
      let labels = [];
      let total = this.axisSize;
      while(total < this.max){
        labels.push(total);
        total += this.axisSize;
      }
      return labels;
    },
    slope() {
      let path = [];
      this.graphFigures.forEach((amt, year) => {
        path.push(
           ["L", this.pointX(year), this.pointY(amt)].join(" ")
        )
      });
      return "M" + [this.pointX(0), this.pointY(this.initial)].join(" ") + path.join(" ");
    },
    path(){
      let path = [];
      this.graphFigures[this.graphFigures.length - 1]
      path.push(["L" , this.pointX(this.graphFigures.length + 2), this.pointY(this.graphFigures[this.graphFigures.length-1])].join(" "))
      path.push(["L" , this.pointX(this.graphFigures.length + 2), this.svg.height].join(" "))
      path.push(["L" , this.pointX(0), this.svg.height].join(" "))
      path.push(["L" , this.pointX(0), this.pointY(this.initial)].join(" "))
      return this.slope + path.join(" ");
    }
  },
  methods: {
    updateMouse(evt){
      this.pt.x = evt.clientX;
      this.pt.y = evt.clientY;
      let loc = this.pt.matrixTransform(this.$refs.svg.getScreenCTM().inverse());
      this.mouse.x = loc.x;
      this.mouse.y = loc.y;
      this.mouse.amount = this.max - ((this.mouse.y - this.svg.padding) / this.graphHeight) * this.max;
      this.mouse.age = Math.round(((this.mouse.x - this.svg.paddingH) / this.graphWidth) * this.yearsOnGraph) + this.age;
    },
    pointX(year) {
      return this.svg.paddingH + (year / this.yearsOnGraph) * this.graphWidth;
    },
    pointY(amt) {
      return (
        this.svg.padding +
        this.graphHeight -
        (amt / this.max) * this.graphHeight
      );
    },
  },
  filters: {
    shorthand(amt) {
      if (amt >= 1000000000000000000000000000) {
        amt = amt / 1000000000000000000000000000;
        return Math.round(amt * 10) / 10 + "o";
      }
      if (amt >= 1000000000000000000000000) {
        amt = amt / 1000000000000000000000000;
        return Math.round(amt * 10) / 10 + "sp";
      }
      if (amt >= 1000000000000000000000) {
        amt = amt / 1000000000000000000000;
        return Math.round(amt * 10) / 10 + "sx";
      }
      if (amt >= 1000000000000000000) {
        amt = amt / 1000000000000000000;
        return Math.round(amt * 10) / 10 + "qt";
      }
      if (amt >= 1000000000000000) {
        amt = amt / 1000000000000000;
        return Math.round(amt * 10) / 10 + "qd";
      }
      if (amt >= 1000000000000) {
        amt = amt / 1000000000000;
        return Math.round(amt * 10) / 10 + "t";
      }
      if (amt >= 1000000000) {
        amt = amt / 1000000000;
        return Math.round(amt * 10) / 10 + "b";
      }
      if (amt >= 1000000) {
        amt = amt / 1000000;
        return Math.round(amt * 10) / 10 + "m";
      }
      return Math.round(amt / 1000) + "k";
    },
  },
  mounted(){
    this.pt = this.$refs.svg.createSVGPoint();
  }
}

</script>

<style lang="scss">

$background: rgba(0,0,255, 0.1);
$mobileWidth: 800px;

*{ box-sizing: border-box; }
html, body{
  margin: 0;
  padding: 0;
}
html, body, button, input{
  font-family: 'Trispace', sans-serif;
}

header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  @media(min-width: $mobileWidth){
    padding: 30px;
  }
}

.toggle-button{
  background: $background;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & , svg,  g, path{
    cursor: pointer;
  }
  @media(min-width: $mobileWidth){
    display: none;
  }
}

#settings{
  display: none;
}

.controls{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background: rgba(#fff, 0.9);
  input:checked + &{
    display: block;
  }
  @media(min-width: $mobileWidth){
    display: block;
    position: relative;
    padding: 15px;
    & header{
      display: none;
    }
  }  
}

.controls--inputs{
  padding: 30px;
  @media(min-width: $mobileWidth){
    padding: 0;
  }
}

h1{
  margin: 0;
  font-size: 18px;
  @media(min-width: 600px){
    font-size: 30px;
  }

}
svg{
  display: block;
  width: 100%;
  height: auto;
  cursor: default;
}
#key{
  display: none;
  pointer-events: none;
}
#info-layer:hover + #key{
  display: block;
}
.home{
  display: grid;
  grid-template-columns: 1fr 240px;
  @media(max-width: $mobileWidth){
    display: block;
  }
}
label{
  display: block;
  padding: 15px 0; 
}
input{
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 3px $background;
  border: none;
  &:focus{
    outline: none;
    box-shadow: none;
    background: $background;
  }
}

.menu-button{
  width: 20px;
  height: 20px;
}
</style>